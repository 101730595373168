import isFunction from 'lodash/isFunction'
import isPlainObject from 'lodash/isPlainObject'
import isUndefined from 'lodash/isUndefined'
import { clearArrInObj } from './object'

const _class2type = {}
const toString = _class2type.toString
'Boolean Number String Function Array Date RegExp Object Error Symbol'
  .split(' ').forEach((name, i) => {
    _class2type['[object ' + name + ']'] = name.toLowerCase()
  })

const ZERO = 0
const NUM_ONE = 1

const isDef = (val) => {
  return !isUndefined(val)
}

const _isType = (obj) => {
  if (obj == null) {
    return obj + ''
  }

  if (typeof obj === 'object' || typeof obj === 'function') {
    return _class2type[toString.call(obj)] || 'object'
  }

  return typeof obj
}

const isString = (val) => {
  return _isType(val) === 'string'
}

/* eslint-disable */
const _extend = function () {
  let options, name, src, copy, copyIsArray, clone
  const isStrict = arguments[0] || false
  let target = arguments[1] || {}
  let i = 2
  const length = arguments.length
  let deep = false

  // Handle a deep copy situation
  if (typeof target === 'boolean') {
    deep = target

    // Skip the boolean and the target
    target = arguments[i] || {}
    i++
  }

  // Handle case when target is a string or something (possible in deep copy)
  if (typeof target !== 'object' && !isFunction(target)) {
    target = {}
  }

  // Extend jQuery itself if only one argument is passed
  if (i === length) {
    target = this
    i--
  }

  for (; i < length; i++) {
    // Only deal with non-null/undefined values
    options = arguments[i]
    if (options != null) {
      // Extend the base object
      for (name in options) {
        src = target[name]
        copy = options[name]

        // Prevent never-ending loop
        if (target === copy || (isStrict && !isUndefined(src) && _isType(src) !== _isType(copy))) {
          continue
        }

        // Recurse if we're merging plain objects or arrays
        copyIsArray = Array.isArray(copy)
        if (deep && copy && (isPlainObject(copy) || copyIsArray)) {
          if (copyIsArray) {
            copyIsArray = false
            clone = src && Array.isArray(src) ? src : []
          } else {
            clone = src && isPlainObject(src) ? src : {}
          }

          // Never move original objects, clone them
          target[name] = _extend(isStrict, deep, clone, copy)

        // Don't bring in undefined values
        } else if (copy !== undefined) {
          target[name] = copy
        }
      }
    }
  }

  // Return the modified object
  return target
}
/* eslint-enable */

/**
 * 对象继承，参考jquery extend
 * @function
 * @param  {...any} params
 * @returns {object}
 */
export const extend = function (...params) {
  params.unshift(false)
  return _extend.apply(this, params)
}

/**
 * 严格继承，也是深拷贝继承
 * @function
 * @param  {...any} params
 * @returns {object}
 */
export const strictExtend = function (...params) {
  let firstParam = {}
  const param0 = params[ZERO]
  if (Array.isArray(param0)) {
    firstParam = []
  } else if (isString(param0)) {
    let resultVal = param0

    for (let i = 1, len = params.length; i < len; i++) {
      const val = params[i]
      if (isString(val)) {
        resultVal = val
      }
    }
    return resultVal
  }
  params.unshift(true, true, firstParam)
  return _extend.apply(this, params)
}

const _deleteArrItemForUnArrItem = (arr) => {
  /* istanbul ignore next */
  if (!Array.isArray(arr)) return

  for (let i = arr.length - NUM_ONE; i >= ZERO; i--) {
    if (!Array.isArray(arr[i])) {
      arr.splice(i, NUM_ONE)
    }
  }
}

/* eslint-disable */
const _modelExtend = function (...params) {
  let options, name, src, copy, copyIsArray, clone
  let target = arguments[0]
  let i = 1
  const length = arguments.length

  if (isString(target)) {
    return strictExtend.apply(this, params)
  }
  // Handle case when target is a string or something (possible in deep copy)
  if (typeof target !== 'object' && !isFunction(target)) {
    target = {}
  }
  if (arguments.length === 1) {
    return clearArrInObj(target)
  }

  if (Array.isArray(target) && target.length > 0) {
    target = target.splice(0, 1)
  }

  for (; i < length; i++) {
    // Only deal with non-null/undefined values
    options = arguments[i]
    if (options === undefined || options === null) {
      options = {}
    }

    if (!isString(options) && !Object.keys(options).length) {
      return clearArrInObj(target)
    }

    if (Array.isArray(target)) {
      if (Array.isArray(options)) {
        if (Array.isArray(target[0])) {
          _deleteArrItemForUnArrItem(options)
        }
      } else {
        return []
      }
    }

    let arrFirst
    if (isDef(target[0])) {
      arrFirst = strictExtend(target[0])
    }

    // Extend the base object
    for (name in options) {
      // fix：ie8打补丁时，原型上挂了属性方法
      if (!options.hasOwnProperty(name)) {
        continue
      }

      src = target[name]
      copy = options[name]

      if (Array.isArray(target) && target.length > 0) {
        if (Array.isArray(options)) {
          target[name] = _modelExtend(strictExtend(arrFirst), copy)
        }
        continue
      } else if (Array.isArray(src) && src.length > 0) {
        if (Array.isArray(copy)) {
          if (copy.length) {
            target[name] = _modelExtend(src, copy)
          } else {
            target[name] = []
          }
        } else {
          target[name] = []
        }
        continue
      }

      if (!isDef(src)) {
        continue
      }

      // Prevent never-ending loop
      if (target === copy || (isDef(src) && _isType(src) !== _isType(copy))) {
        continue
      }

      // Recurse if we're merging plain objects or arrays
      if (copy && isPlainObject(copy)) {
        clone = src && isPlainObject(src) ? src : {}

        // Never move original objects, clone them
        target[name] = _modelExtend(clone, copy)

      // Don't bring in undefined values
      } else if (copy !== undefined) {
        target[name] = copy
      }
    }
    for (name in target) {
      if (!isDef(options[name])) {
        if (Array.isArray(target[name])) {
          target[name] = []
        }
      }
    }
  }

  // Return the modified object
  return target
}

/* eslint-enable */

/**
 * 模型继承，返回以第一个参数为基础的严格继承
 * @function
 * @param  {...any} params
 */
export const modelExtend = function (...params) {
  const fristParamType = _isType(params[ZERO])
  if (fristParamType === 'object' || fristParamType === 'array') {
    params[ZERO] = strictExtend(params[ZERO])
  }

  return _modelExtend.apply(this, params)
}
