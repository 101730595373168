// 参考：https://github.com/AdonisLau/axios-jsonp
// version: 1.0.4

/* istanbul ignore file */

let cid = 1

function buildParams (params) {
  const result = []

  for (const i in params) {
    result.push(encodeURIComponent(i) + '=' + encodeURIComponent(params[i]))
  }

  return result.join('&')
}

// eslint-disable-next-line max-lines-per-function
export default function (config) {
  // eslint-disable-next-line max-lines-per-function, sonarjs/cognitive-complexity
  return new Promise(function (resolve, reject) {
    let script = document.createElement('script')
    let src = config.url

    if (config.params) {
      const params = buildParams(config.params)

      if (params) {
        src += (src.indexOf('?') >= 0 ? '&' : '?') + params
      }
    }

    script.async = true

    function remove () {
      if (script) {
        script.onload = script.onreadystatechange = script.onerror = null

        if (script.parentNode) {
          script.parentNode.removeChild(script)
        }

        script = null
      }
    }

    const jsonp = config.jsonpCallback || `axiosJsonpCallback${cid++}`
    const old = window[jsonp]
    let isAbort = false

    const timeout = config.timeout

    if (timeout > 0) {
      setTimeout(() => {
        isAbort = true

        resolve(`timeout of ${timeout}ms exceeded`)
      }, timeout)
    }

    window[jsonp] = function (responseData) {
      window[jsonp] = old

      if (isAbort) {
        return
      }

      const response = {
        data: responseData,
        status: 200
      }

      resolve(response)
    }

    const additionalParams = {}
    if (!config.cache) {
      additionalParams._ = (new Date().getTime())
    }

    additionalParams[config.jsonp || 'callback'] = jsonp

    src += (src.indexOf('?') >= 0 ? '&' : '?') + buildParams(additionalParams)

    script.onload = script.onreadystatechange = function () {
      if (!script.readyState || /loaded|complete/.test(script.readyState)) {
        remove()
      }
    }

    script.onerror = function () {
      remove()

      reject(new Error('Network Error'))
    }

    if (config.cancelToken) {
      config.cancelToken.promise.then(function (cancel) {
        if (!script) {
          return
        }

        isAbort = true

        reject(cancel)
      })
    }

    script.src = src

    document.head.appendChild(script)
  })
}
