import isPlainObject from 'lodash/isPlainObject'

const _uid = () => {
  let id = 1
  return () => {
    return id++
  }
}

/**
 * 唯一ID
 * @function
 * @returns {number}
 */
export const uid = _uid()

const updateVmVal = function (baseData) {
  if (!(this && isPlainObject(baseData))) { return }

  Object.keys(baseData).forEach(key => {
    this[key] = baseData[key]
  })
}

/**
 * 重置对象下的属性值
 * @function
 * @param {object} baseData
 */
export const resetField = function (baseData) {
  updateVmVal.call(this, baseData)
}

/**
 * 变量是否定义过
 * @param {any} v
 */
export const isDef = v => v !== undefined
